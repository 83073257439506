// Custom css

require('../mystyles.scss');

// Fonts

import fontRegular from '../fonts/browallia-new.ttf';

// Images

import favicon32 from '../favicon32x32.ico';
import favicon57 from '../favicon57x57.ico';
import favicon76 from '../favicon76x76.ico';
import favicon96 from '../favicon96x96.ico';
import favicon120 from '../favicon120x120.ico';
import favicon128 from '../favicon128x128.ico';
import favicon144 from '../favicon144x144.ico';
import favicon152 from '../favicon152x152.ico';
import favicon180 from '../favicon180x180.ico';
import favicon192 from '../favicon192x192.ico';
import favicon196 from '../favicon196x196.ico';
import favicon228 from '../favicon228x228.ico';

import about from '../img/background/about.png';
import application from '../img/background/application.png';
import contact from '../img/background/contact.png';
import home from '../img/background/home.png';
import loan from '../img/background/loan.png';
import requirements from '../img/background/requirements.png';

import check from '../img/check.svg';
import logo from '../img/logo.svg';
import rightArrow from '../img/right-arrow.svg';

// Custom script

var $ = require("jquery");

import scripts from '../js/scripts';
import 'bootstrap';


